import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/rcMain/home.vue'),
    meta: { layout: 'main', title: '', forGuest: true }
  }
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import(/* webpackChunkName: "login" */ '../views/rcAuth/login.vue'),
  //   meta: { layout: 'login', title: 'Log in', forGuest: true }
  // },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import(/* webpackChunkName: "register" */ '../views/rcAuth/register.vue'),
  //   meta: { layout: 'login', title: 'Log in', forGuest: true }
  // },
  // {
  //   path: '/casa',
  //   component: () => import(/* webpackChunkName: "casa" */ '../views/rcCasa/container.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'casa',
  //       component: () => import(/* webpackChunkName: "casa-index" */ '../views/rcCasa/index.vue'),
  //       meta: { layout: 'casa', title: 'casa', forGuest: false }
  //     },
  //     {
  //       path: 'vehicle',
  //       component: () =>
  //         import(/* webpackChunkName: "casa-vehicle-tracker" */ '../views/rcCasa/vehicleTracker/index.vue'),
  //       meta: { layout: 'casa', title: 'vehicle tracker', forGuest: false },
  //       children: [
  //         {
  //           path: '',
  //           name: 'vehicle-tracker',
  //           component: () =>
  //             import(/* webpackChunkName: "casa-vehicle-tracker-list" */ '../views/rcCasa/vehicleTracker/list.vue'),
  //           meta: { layout: 'casa', title: 'vehicle tracker', forGuest: false }
  //         },
  //         {
  //           path: 'fuel-record',
  //           name: 'add-fuel-record',
  //           component: () =>
  //             import(
  //               /* webpackChunkName: "casa-vehicle-tracker-add-fuel-record" */ '../views/rcCasa/vehicleTracker/addFuelRecord.vue'
  //             ),
  //           meta: { layout: 'casa', title: 'fuel record', forGuest: false }
  //         },
  //         {
  //           path: 'service-record',
  //           name: 'add-service-record',
  //           component: () =>
  //             import(
  //               /* webpackChunkName: "casa-vehicle-tracker-add-service-record" */ '../views/rcCasa/vehicleTracker/addServiceRecord.vue'
  //             ),
  //           meta: { layout: 'casa', title: 'service record', forGuest: false }
  //         },
  //         {
  //           path: 'fuel-log',
  //           name: 'view-fuel-log',
  //           component: () =>
  //             import(
  //               /* webpackChunkName: "casa-vehicle-tracker-view-fuel-log" */ '../views/rcCasa/vehicleTracker/viewFuelLog.vue'
  //             ),
  //           meta: { layout: 'casa', title: 'fuel log', forGuest: false }
  //         },
  //         {
  //           path: 'service-log',
  //           name: 'view-service-log',
  //           component: () =>
  //             import(
  //               /* webpackChunkName: "casa-vehicle-tracker-view-service-log" */ '../views/rcCasa/vehicleTracker/viewServiceLog.vue'
  //             ),
  //           meta: { layout: 'casa', title: 'service log', forGuest: false }
  //         },
  //         {
  //           path: 'summary',
  //           name: 'vehicle-summary',
  //           component: () =>
  //             import(
  //               /* webpackChunkName: "casa-vehicle-tracker-vehicle-summary" */ '../views/rcCasa/vehicleTracker/vehicleSummary.vue'
  //             ),
  //           meta: { layout: 'casa', title: 'vehicle summary', forGuest: false }
  //         }
  //       ]
  //     }
  //   ]
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? 'ryancapehart | ' + to.meta.title : 'ryancapehart.com'
  next()
})

export default router
