<template>
  <v-app>
    <v-app-bar color="primary" app dark>
      <v-spacer></v-spacer>
      <v-btn :to="{ name: 'home' }" exact text>home</v-btn>
    </v-app-bar>
    <v-main class="grey lighten-4">
      <router-view />
    </v-main>
    <v-footer app color="grey darken-3" dark>
      <v-row>
        <v-col class="text-center"> &copy; {{ new Date().getFullYear() }} Ryan Capehart </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'MainLayout',
  data() {
    return {
      //
    }
  },
  methods: {}
}
</script>
