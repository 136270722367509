import axios from 'axios'
import { getToken } from '@/shared/utils/auth'

const api = axios.create({
  baseURL: 'https://api.ryancapehart.com/api/'
})

export function executeApi(method, resource, data, headers) {
  return api({
    method,
    url: resource,
    data,
    headers: headers ? headers : {}
  })
}

export function secureApi(method, resource, data, headers) {
  return api({
    method,
    url: resource,
    data,
    headers: headers ? headers : { Authorization: 'Bearer ' + getToken() }
  })
}
