export function isLoggedIn() {
  return JSON.parse(localStorage.isLoggedIn)
}

export function logIn() {
  localStorage.setItem('isLoggedIn', true)
}

export function logOut() {
  localStorage.setItem('isLoggedIn', false)
}

export function setToken(token) {
  localStorage.setItem('token', token)
}

export function getToken() {
  return localStorage.token
}
