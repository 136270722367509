<template>
  <v-app>
    <!-- <rc-layout-header-main></rc-layout-header-main> -->
    <v-main>
      <v-container fluid pa-0>
        <router-view />
      </v-container>
    </v-main>
    <rc-layout-footer-main></rc-layout-footer-main>
  </v-app>
</template>

<script>
import rcLayoutFooterMain from '@/components/rcLayout/rcLayoutFooterMain.vue'
// import rcLayoutHeaderMain from '@/components/rcLayout/rcLayoutHeaderMain.vue'

export default {
  name: 'MainLayout',
  components: {
    rcLayoutFooterMain
    // rcLayoutHeaderMain
  },
  data() {
    return {
      //
    }
  },
  methods: {}
}
</script>
