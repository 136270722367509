<template>
  <v-app>
    <v-app-bar color="primary" app dark>
      <v-app-bar-nav-icon @click.stop="dashNav = !dashNav" />
      <v-spacer></v-spacer>
      <v-btn v-if="!isLoggedIn" :to="{ name: 'login' }" text>login</v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="dashNav" app clipped fixed temporary disable-resize-watcher>
      <casa-nav />
      <template v-slot:append>
        <div class="pa-2" v-if="isLoggedIn">
          <v-btn outlined block color="red">logout</v-btn>
        </div>
        <div class="pa-2" v-else>
          <v-btn :to="{ name: 'login' }" block color="primary">login</v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <v-footer app color="grey darken-3" dark>
      <v-row>
        <v-col class="text-center"> &copy; {{ new Date().getFullYear() }} Ryan Capehart </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import casaNav from '@/components/rcLayout/casaNav.vue'
import auth from '@/shared/mixins/auth'

export default {
  name: 'CasaLayout',
  mixins: [auth],
  components: {
    casaNav
  },
  data() {
    return {
      dashNav: false
    }
  },
  methods: {}
}
</script>
