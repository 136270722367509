export default {
  data() {
    return {
      //
    }
  },
  computed: {
    isLoggedIn() {
      return JSON.parse(localStorage.isLoggedIn)
    }
  },
  methods: {
    //
  }
}
