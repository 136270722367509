<template>
  <v-row :no-gutters="gutters">
    <v-col cols="12" class="text-center">
      <v-btn
        v-for="(btn, index) in socialLinks"
        :key="'social_link_' + index"
        icon
        :color="color"
        :href="btn.href"
        class="mr-4"
      >
        <v-icon>{{ btn.icon }}</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'rcContactSocialLinks',
  props: {
    color: { type: String, default: 'primary' },
    gutters: { type: Boolean, default: false }
  },
  data() {
    return {
      socialLinks: [
        { icon: 'mdi-linkedin', href: 'https://www.linkedin.com/in/ryan-capehart/' }
        // { icon: 'mdi-github', href: '' },
        // { icon: 'mdi-discord', href: '' }
      ]
    }
  }
}
</script>

<style></style>
