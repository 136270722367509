import { isLoggedIn } from '@/shared/utils/auth'
import { secureApi } from '@/shared/utils/api.js'
import ENUM from '@/shared/enums/enums'

const state = {
  apiState: ENUM.INIT,
  serviceTypes: [],
  vehicles: [],
  currentVehicle: '',
  fuelLog: [],
  serviceLog: []
}

const getters = {
  fuelLogForCurrent: (state) => state.fuelLog.filter((log) => log.name === state.currentVehicle),
  serviceLogForCurrent: (state) => state.serviceLog.filter((log) => log.name === state.currentVehicle)
}

const mutations = {
  setApiState(state, status) {
    state.apiState = status
  },
  setVehicles(state, data) {
    state.vehicles = data
  },
  setServiceTypes(state, data) {
    state.serviceTypes = data
  },
  setFuelLog(state, data) {
    state.fuelLog = data
  },
  setServiceLog(state, data) {
    state.serviceLog = data
  },
  setCurrentVehicle(state, data) {
    state.currentVehicle = data
  }
}

const actions = {
  async fetchServiceTypes({ commit }) {
    if (isLoggedIn) {
      try {
        var data = (await secureApi('get', '/v1/services')).data
        commit('setServiceTypes', data)
      } catch (error) {
        console.log(error)
      }
    }
  },
  async fetchVehicles({ commit }) {
    if (isLoggedIn) {
      try {
        commit('setApiState', ENUM.LOADING)
        var data = (await secureApi('get', '/v1/vehicles')).data
        commit('setVehicles', data)
        commit('setApiState', ENUM.LOADED)
      } catch (error) {
        console.log(error)
        commit('setApiState', ENUM.ERROR)
      }
    }
  },
  async fetchFuelLog({ commit }) {
    if (isLoggedIn) {
      try {
        commit('setApiState', ENUM.LOADING)
        var data = (await secureApi('get', '/v1/vehicles/fuel/log')).data
        commit('setFuelLog', data)
        commit('setApiState', ENUM.LOADED)
      } catch (error) {
        console.log(error)
        commit('setApiState', ENUM.ERROR)
      }
    }
  },
  async fetchServiceLog({ commit }) {
    if (isLoggedIn) {
      try {
        commit('setApiState', ENUM.LOADING)
        var data = (await secureApi('get', '/v1/vehicles/service/log')).data
        commit('setServiceLog', data)
        commit('setApiState', ENUM.LOADED)
      } catch (error) {
        console.log(error)
        commit('setApiState', ENUM.ERROR)
      }
    }
  },
  async updateFuelLog({ commit, dispatch }, data) {
    if (isLoggedIn) {
      try {
        commit('setApiState', ENUM.LOADING)
        await secureApi('post', '/v1/vehicles/fuel/add', data)
        dispatch('fetchVehicles')
        commit('setApiState', ENUM.LOADED)
      } catch (error) {
        console.log(error)
        commit('setApiState', ENUM.ERROR)
      }
    }
  },
  async updateServiceLog({ commit, dispatch }, data) {
    if (isLoggedIn) {
      try {
        commit('setApiState', ENUM.LOADING)
        await secureApi('post', '/v1/vehicles/service/add', data)
        dispatch('fetchVehicles')
        dispatch('fetchServiceTypes')
        commit('setApiState', ENUM.LOADED)
      } catch (error) {
        console.log(error)
        commit('setApiState', ENUM.ERROR)
      }
    }
  },
  async setCurrentVehicle({ commit }, data) {
    if (isLoggedIn) {
      commit('setCurrentVehicle', data)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
