import { setToken } from '@/shared/utils/auth'
import { executeApi } from '@/shared/utils/api.js'
import ENUM from '@/shared/enums/enums'

const state = {
  apiState: ENUM.INIT,
  user: {}
}

const getters = {}

const mutations = {
  setApiState(state, status) {
    state.apiState = status
  },
  setUser(state, payload) {
    state.user = payload
  }
}

const actions = {
  async login({ commit }, data) {
    try {
      commit('setApiState', ENUM.LOADING)
      const response = (
        await executeApi('post', '/v1/login', data, {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      ).data
      setToken(response.token)
      commit('setUser', response)
      commit('setApiState', ENUM.LOADED)
    } catch (error) {
      console.log(error)
      commit('setApiState', ENUM.ERROR)
    }
  },
  async register({ commit, dispatch }, data) {
    try {
      commit('setApiState', ENUM.LOADING)
      await executeApi('post', '/v1/vehicles/fuel/add', data)
      dispatch('fetchVehicles')
      commit('setApiState', ENUM.LOADED)
    } catch (error) {
      console.log(error)
      commit('setApiState', ENUM.ERROR)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
