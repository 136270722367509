<template>
  <v-footer color="primary lighten-1">
    <v-container fluid>
      <social-links :gutters="true" :color="'white'" />
      <v-row no-gutters class="my-2">
        <v-col cols="12" class="text-center text-overline white--text">
          <span class="">&copy;{{ new Date().getFullYear() }} — Ryan Capehart</span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import socialLinks from '@/components/rcMain/rcContactSocialLinks.vue'

export default {
  name: 'rcLayoutFooterMain',
  components: { socialLinks },
  data() {
    return {
      footerLinks: [
        { text: 'home', to: 'Home' },
        { text: 'services', to: 'Services' },
        { text: 'about', to: 'About' },
        { text: 'contact', to: 'Contact' }
      ]
    }
  }
}
</script>

<style></style>
