<template>
  <div>
    <v-list class="pt-0">
      <v-list-item-group>
        <v-list-item class="primary" dark inactive :ripple="false">
          <v-list-item-content>
            <v-list-item-title> ryancapehart.com </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-list>
      <v-subheader>VEHICLE TRACKING</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(link, index) in vehicleLinks"
          :key="'link_' + index"
          :to="{ name: link.to }"
          exact
          class="text-capitalize"
        >
          <v-list-item-content>{{ link.text }}</v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
    <v-list>
      <v-subheader>KITCHEN</v-subheader>
    </v-list>
    <v-divider></v-divider>
    <v-list>
      <v-subheader>OTHER</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(link, index) in consultLinks"
          :key="'link_' + index"
          :to="{ name: link.to }"
          exact
          class="text-capitalize"
        >
          <v-list-item-content>{{ link.text }}</v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'CasaNav',
  data() {
    return {
      consultLinks: [{ to: 'home', text: 'consulting' }],
      vehicleLinks: [
        { to: 'add-fuel-record', text: 'add fuel record' },
        { to: 'view-fuel-log', text: 'view fuel log' },
        { to: 'add-service-record', text: 'add service record' },
        { to: 'view-service-log', text: 'view service log' },
        { to: 'vehicle-summary', text: 'vehicle summary' }
      ]
    }
  }
}
</script>

<style></style>
