import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)

Vue.component('main-layout', require('./layouts/main.vue').default)
Vue.component('casa-layout', require('./layouts/casa.vue').default)
Vue.component('login-layout', require('./layouts/login.vue').default)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      // store.dispatch('user/logout')
      router.push({ name: 'login' })

      return Promise.reject(error)
    }
  }
)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  async beforeCreate() {
    if (!localStorage.isLoggedIn) {
      localStorage.setItem('isLoggedIn', false)
    }
  }
}).$mount('#app')
